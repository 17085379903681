import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sharedStyles from '@dabea/common/lib/styles/styled'
import COLORS from '@dabea/common/lib/constants/colors'

const Headline = styled.h4`
  ${sharedStyles.menuFont}
  color: ${COLORS.BRAND_COLOR_ACCENT};
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const SubHeader = ({ text }) => <Headline>{text}</Headline>

SubHeader.propTypes = {
  text: PropTypes.string.isRequired,
}

export default SubHeader
