import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sharedStyles from '@dabea/common/lib/styles/styled'
import COLORS from '@dabea/common/lib/constants/colors'

const Headline = styled.h2`
  ${sharedStyles.headlineFont}
  color: ${COLORS.BRAND_COLOR_ACCENT};
  font-size: 28px;
  padding-top: 20px;
  line-height: 35px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const Header = ({ text }) => <Headline>{text}</Headline>

Header.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Header
