"use strict";

var production = require('./production');
var development = require('./development');
var local = require('./local');
var configuration = null;
if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
  configuration = production;
} else if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
  configuration = local;
} else {
  configuration = development;
}
module.exports = {
  firebase: configuration.firebase,
  firestore: configuration.firestore,
  auth: configuration.auth,
  storage: configuration.storage
};