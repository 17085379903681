"use strict";

var firebaseConfig = {
  apiKey: 'AIzaSyDpI-VKuo6qmc0xaB6UyXzZ-Pcaj9aMd-U',
  authDomain: 'dabea-prod.firebaseapp.com',
  databaseURL: 'https://dabea-prod.firebaseio.com',
  projectId: 'dabea-prod',
  storageBucket: 'dabea-prod.appspot.com',
  messagingSenderId: '35125523325',
  appId: '1:35125523325:web:89872663a2a264054b9214',
  measurementId: 'G-Y3743SX8S8'
};
var firestoreConfig = {};
var authConfig = {};
var storageConfig = {};
module.exports = {
  firebase: firebaseConfig,
  firestore: firestoreConfig,
  auth: authConfig,
  storage: storageConfig
};