import { combineReducers } from 'redux'
import signup from 'reducers/signup'
import modal from 'reducers/modal'

const appReducer = combineReducers({
  signup,
  modal,
})

export default appReducer
