import { SIGNUP } from 'actions/actionTypes'
import { apiStatus } from 'api/utils'

const initialState = {
  status: null,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case `RESPONSE_${SIGNUP}`: {
      return {
        ...state,
        status: apiStatus.SUCCESS,
        error: null,
      }
    }
    case `REQUEST_${SIGNUP}`: {
      return {
        ...state,
        status: apiStatus.PENDING,
        error: null,
      }
    }
    case `FAILED_${SIGNUP}`: {
      return {
        ...state,
        status: apiStatus.ERROR,
        error: action.err,
      }
    }
    default:
      return state
  }
}
