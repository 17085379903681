"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var COLORS = {
  WHITE: '#FFF',
  BLACK: '#000',
  BACKGROUND: '#ddeaf5',
  BUTTON_BACKGROUND: '#03a9f4',
  BUTTON_DEFAULT: '#3fa2f7',
  BRAND_COLOR_ACCENT: '#2d4bf2',
  BRAND_COLOR_BODYLIGHT: '#737686',
  BRAND_COLOR_LIGHTBLUE: '#f7f7ff',
  BRAND_COLOR_BODY: '#061148',
  BRAND_COLOR_GREEN: '#1FC986',
  RESET_HEADLINE: '#0d4575',
  BACKGROUND_BLUE: '#0069ff',
  HEADLINE_PURPLE: '#33305f',
  HEADLINE_GREEN: '#24b47e',
  HEADER: '#3e3e3e',
  UNDERLINE_BACKGROUND: '#3faac6',
  BADGE_BACKGROUND: '#03A9F4',
  ABOUT_BACKGROUND: '#ddeaf5',
  STYLED_INPUT_FIELD_WHITE: '#E4F1FD',
  STYLED_INPUT_FIELD_BLUE: '#297FCA',
  TEXT_FONT: '#525f7f',
  BORDER: '#f2f3f9',
  MENU_FONT: '#25222c',
  DENY_BUTTON_BACKGROUND: '#e60202',
  APPROVAL_BUTTON_BACKGROUND: '#44b500',
  PRIMARY_SUCCESS: '#66c72a',
  PRIMARY_ALERT: '#ca3a3a',
  PRIMARY_WARNING: '#f1ad3d',
  CHART_PRIMARY: '#12939A',
  CHART_SECONDARY: '#EF5D28',
  ROW_BACKGROUND: '#f5f5f5'
};
var _default = exports["default"] = COLORS;