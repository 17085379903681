import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'

export const createStore = () =>
  reduxCreateStore(rootReducer, compose(applyMiddleware(thunk)))

export const store = createStore()

export default null
