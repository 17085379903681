import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getAnalytics, logEvent } from 'firebase/analytics'
import Header from 'components/ContactModal/Components/Header'
import SubHeader from 'components/ContactModal/Components/SubHeader'
import InputField from '@dabea/common/lib/components/Input'
import Button from '@dabea/common/lib/components/Button'
import COLORS from '@dabea/common/lib/constants/colors'
import * as sharedStyles from '@dabea/common/lib/styles/styled'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'

const styles = {
  font: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
  },
}

const PageWrap = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`

const InputWrap = styled.div`
  width: 50%;
  margin: 0 auto;
  padding-top: 20px;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const ButtonWrap = styled.div`
  width: 30%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 150px;
  }
`

const Label = styled.div`
  ${sharedStyles.menuFont}
  color: ${COLORS.BRAND_COLOR_ACCENT};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const MoreInfoPage = ({ uid, setRemoveModal, clearModalState }) => {
  const [telephone, setTelephone] = useState('')
  const validInput = telephone && telephone.length >= 8
  const sendInfo = () => {
    // Save data
    logEvent(getAnalytics(), 'modal_send_telephone_info')

    const db = getFirestore()
    const docRef = doc(db, 'leads', uid)
    updateDoc(docRef, { telephone })
    setRemoveModal()
    clearModalState()
  }
  return (
    <>
      <PageWrap>
        <Header text="TAK!" />
        <SubHeader text="Hvis du vil ringes op og høre om vores gode tilbud, kan du oplyse dit nummer her." />
        <InputWrap key="telephone">
          <Label>Telefonnummer</Label>
          <InputField
            background="#FFF"
            color="#061148"
            name="Telefon"
            onChange={(e) => setTelephone(e.target.value)}
            type="text"
            placeholder="Indtast telefon nummer"
          />
        </InputWrap>
        <ButtonWrap>
          <Button
            id="info-page-btn-send-more-info"
            dataTest="send-button"
            styles={styles.font}
            onClick={() => sendInfo()}
            isRounded
            hasShadow
            disabled={!validInput}
            color={validInput ? '#2d4bf2' : '#FFF'}
            textColor={validInput ? '#FFF' : '#000'}
            height={50}
          >
            SEND
          </Button>
        </ButtonWrap>
      </PageWrap>
    </>
  )
}

MoreInfoPage.propTypes = {
  uid: PropTypes.string.isRequired,
  setRemoveModal: PropTypes.func.isRequired,
  clearModalState: PropTypes.func.isRequired,
}

export default MoreInfoPage
