import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import Header from 'components/ContactModal/Components/Header'
import SubHeader from 'components/ContactModal/Components/SubHeader'
import InputField from '@dabea/common/lib/components/Input'
import Button from '@dabea/common/lib/components/Button'
import ValidateEmail from '@dabea/common/lib/utils/login/validateEmail'

import * as sharedStyles from '@dabea/common/lib/styles/styled'
import COLORS from '@dabea/common/lib/constants/colors'

const styles = {
  font: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 600,
  },
}

const PageWrap = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`

const InputWrap = styled.div`
  width: 50%;
  margin: 0 auto;
  padding-top: 20px;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const ButtonWrap = styled.div`
  width: 30%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 150px;
  }
`

const Label = styled.div`
  ${sharedStyles.menuFont}
  color: ${COLORS.BRAND_COLOR_ACCENT};
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`

const IntroPage = ({ setCurrentPage, uid }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const validInput = name && name.length && ValidateEmail(email)
  const sendInfo = () => {
    // Save data
    logEvent(getAnalytics(), 'modal_send_contact_info')

    const db = getFirestore()
    const data = {
      name,
      email,
      signUpDate: new Date(),
      dabeaFree: true,
    }

    const document = doc(db, 'leads', uid)

    setDoc(document, data)
    setCurrentPage(2)
  }
  return (
    <>
      <PageWrap>
        <Header text="Vi lancerer snart" />
        <SubHeader text="Lad os kontakte jer, så snart vi er klar med DABEA FREE til jeres forening." />
        <InputWrap key="name">
          <Label>Navn</Label>
          <InputField
            background="#FFF"
            color="#061148"
            name="Name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Indtast navn"
          />
        </InputWrap>
        <InputWrap key="email">
          <Label>Email</Label>
          <InputField
            background="#FFF"
            color="#061148"
            name="Email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Indtast email"
          />
        </InputWrap>
        <ButtonWrap>
          <Button
            id="info-page-btn-send-name"
            dataTest="send-button"
            styles={styles.font}
            onClick={() => sendInfo()}
            isRounded
            hasShadow
            disabled={!validInput}
            color={validInput ? '#2d4bf2' : '#FFF'}
            textColor={validInput ? '#FFF' : '#000'}
            height={50}
          >
            Send
          </Button>
        </ButtonWrap>
      </PageWrap>
    </>
  )
}

IntroPage.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
}

export default IntroPage
