"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.textFont = exports.panelHeading = exports.panelBody = exports.panel = exports.menuFont = exports.link = exports.headlineFont = exports.dynamicHeadlineFont = exports.content = exports.button = exports.boxShadow = void 0;
var _styledComponents = require("styled-components");
var _colors = _interopRequireDefault(require("../constants/colors"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function _taggedTemplateLiteral(e, t) { return t || (t = e.slice(0)), Object.freeze(Object.defineProperties(e, { raw: { value: Object.freeze(t) } })); }
// TODO: make this file default when radium is gone

var content = exports.content = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 90%;\n  margin: 0 auto;\n"])));
var panel = exports.panel = (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border: 1px solid blue;\n  border-color: ", ";\n  min-height: 35px;\n"])), _colors["default"].BORDER);
var panelHeading = exports.panelHeading = (0, _styledComponents.css)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: white;\n  border: none;\n"])));
var panelBody = exports.panelBody = (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-bottom: 3px solid;\n"])));
var button = exports.button = (0, _styledComponents.css)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  outline: none;\n"])));
var link = exports.link = (0, _styledComponents.css)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  text-align: center;\n"])), _colors["default"].BRAND_COLOR_ACCENT);
var menuFont = exports.menuFont = (0, _styledComponents.css)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: 600;\n  font-size: 14px;\n  font-family: Lato, sans-serif;\n"])), _colors["default"].BRAND_COLOR_BODY);
var headlineFont = exports.headlineFont = (0, _styledComponents.css)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-weight: 600;\n  font-size: 2.5em;\n  color: ", ";\n  font-family: Lato, sans-serif;\n  text-align: center;\n"])), _colors["default"].BRAND_COLOR_BODY);
var dynamicHeadlineFont = exports.dynamicHeadlineFont = (0, _styledComponents.css)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n  @media (max-width: 834px) {\n    font-size: 2em;\n  }\n"])), headlineFont);
var textFont = exports.textFont = (0, _styledComponents.css)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: 400;\n  font-size: 16px;\n  font-family: Lato, sans-serif;\n  text-align: center;\n"])), _colors["default"].BRAND_COLOR_BODY);
var boxShadow = exports.boxShadow = (0, _styledComponents.css)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  -webkit-box-shadow: -6px 6px 36px 0px rgba(0, 0, 0, 0.1);\n  box-shadow: -6px 6px 36px 0px rgba(0, 0, 0, 0.1);\n"])));