import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { uid as Uid } from '@dabea/common/lib/utils/uid'
import Modal from 'react-modal'
import COLORS from '@dabea/common/lib/constants/colors'
import IntroPage from './Pages/IntroPage'
import MoreInfo from './Pages/MoreInfo'

const customStyles = {
  overlay: {
    background: 'rgba(214, 214, 214, 0.9)',
    zIndex: '1',
  },
}

const StyledModal = styled(Modal)`
  background: #f7f7ff;
  bottom: auto;
  -webkit-box-shadow: -6px 6px 36px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -6px 6px 36px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  left: 50%;
  min-height: 100px;
  margin-right: -50%;
  outline: none;
  padding: 0;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2147483547;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
  }
`

const CloseIcon = styled.a`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  :hover {
    opacity: 1;
  }
  :before,
  :after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 3px;
    background-color: ${COLORS.BRAND_COLOR_ACCENT};
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
  }
`

const getCurrentPage = (
  page,
  setCurrentPage,
  uid,
  setRemoveModal,
  clearModalState
) => {
  switch (page) {
    case 2:
      return (
        <MoreInfo
          uid={uid}
          setRemoveModal={setRemoveModal}
          clearModalState={clearModalState}
        />
      )
    case 1:
    default:
      return <IntroPage setCurrentPage={setCurrentPage} uid={uid} />
  }
}

const ContactModal = ({ showModal, setRemoveModal }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [uid, setUid] = useState(null)
  useEffect(() => {
    if (!uid) setUid(Uid(15))
  }, [uid, setUid])
  const clearModalState = () => {
    setCurrentPage(0)
    setUid(Uid(15))
  }
  return (
    <StyledModal
      isOpen={showModal}
      onRequestClose={() => setRemoveModal()}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <CloseIcon href="#" onClick={() => setRemoveModal()} />
      {getCurrentPage(
        currentPage,
        setCurrentPage,
        uid,
        setRemoveModal,
        clearModalState
      )}
    </StyledModal>
  )
}

ContactModal.defaultProps = {
  showModal: false,
}

ContactModal.propTypes = {
  showModal: PropTypes.bool,
  setRemoveModal: PropTypes.func.isRequired,
}

const mapStateToProps = ({ modal }) => ({
  showModal: modal.showModal,
})

const mapDispatchToProps = (dispatch) => ({
  setRemoveModal: () => dispatch({ type: 'SET_REMOVE_MODAL' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal)
