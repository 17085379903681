import React from 'react'
import CookieConsent from 'react-cookie-consent'
import COLORS from '@dabea/common/lib/constants/colors'

const styles = {
  banner: {
    zIndex: 10000,
    fontFamily: 'Lato, sans-serif',
    minHeight: 57,
    backgroundColor: COLORS.BRAND_COLOR_ACCENT,
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    lineHeight: '22px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: 0,
  },
  link: {
    fontFamily: 'Roboto, sans-serif',
    color: '#FFF',
  },
  content: {
    flex: '0 0 100%',
    textAlign: 'center',
  },
}

const Cookie = () => (
  <CookieConsent
    buttonText="Accepter"
    cookieName="accept-cookies"
    style={styles.banner}
    buttonStyle={styles.button}
    contentStyle={styles.content}
  >
    Ved brug af dabea.dk accepterer du at vi indsamler cookies til at huske
    indstillinger, statistik og målrette annoncering.&nbsp;&nbsp;
    <a
      style={styles.link}
      href="/privatlivspolitik.html"
      target="_blank"
      rel="nofollow"
    >
      Læs mere her
    </a>
  </CookieConsent>
)

export default Cookie
