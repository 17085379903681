/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { store } from './src/store'
import CookieBanner from './src/components/CookieBanner'
import ContactModal from './src/components/ContactModal'
import './src/api/firebase'

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <>
      {element}
      <ContactModal />
      <CookieBanner />
    </>
  </Provider>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
