const initialState = {
  showModal: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SHOW_MODAL': {
      return {
        ...state,
        showModal: true,
        special: !!action.special,
      }
    }
    case 'SET_REMOVE_MODAL': {
      return {
        ...state,
        showModal: false,
      }
    }
    default:
      return state
  }
}
