export const apiStatus = {
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

export const getCloudFunctionHost = () => {
  if (process.env.REACT_APP_DEPLOY_ENV === 'local') {
    return 'http://localhost:5000/dabea-develop/europe-west1/api'
  }

  if (process.env.REACT_APP_DEPLOY_ENV === 'development') {
    return 'https://europe-west1-dabea-develop.cloudfunctions.net/api'
  }

  if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
    return 'https://europe-west1-dabea-prod.cloudfunctions.net/api'
  }

  return 'undefined'
}
