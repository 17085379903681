"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uidNumber = exports.uid = void 0;
var uid = exports.uid = function uid(len) {
  var length = len || 7;
  return Math.random().toString(35).substr(2, length);
};
var uidNumber = exports.uidNumber = function uidNumber(len) {
  var length = len || 12;
  return Math.random().toString().substr(2, length).replace(/^0+/, '');
};