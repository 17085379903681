"use strict";

var firebaseConfig = {
  apiKey: 'AIzaSyD4LwgZ8DhmzWMKtPB2GQsfnnjILSZpM6U',
  authDomain: 'dabea-develop.firebaseapp.com',
  databaseURL: 'https://dabea-develop.firebaseio.com',
  projectId: 'dabea-develop',
  storageBucket: 'dabea-develop.appspot.com',
  messagingSenderId: '350979944724',
  appId: '1:350979944724:web:50bc363fafaf77df08d201',
  measurementId: 'G-R5H8VKYQZT'
};
var firestoreConfig = {
  host: 'localhost',
  port: 8080,
  ssl: false
};
var authConfig = {
  host: 'localhost',
  port: 9099
};
var storageConfig = {
  host: 'localhost',
  port: 9199
};
module.exports = {
  firebase: firebaseConfig,
  firestore: firestoreConfig,
  auth: authConfig,
  storage: storageConfig
};